var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reservation-add-complaint"},[_c('div',{staticClass:"reservation-add-complaint--content"},[(_vm.operatorList.length === 0)?_c('div'):(_vm.operatorList.length === 1)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.operatorList[0].companyName))])]):_c('div',[_c('CRSelect',{attrs:{"id":`reservation-add-complaint-select-provider-${_vm.i}`,"items":_vm.operatorList,"item-text":"companyName","return-object":"","label":_vm.i === 0 ? 'Provider' : '',"placeholder":"Select Provider"},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}})],1),_vm._l((_vm.reasons),function(reasonType,i){return _c('div',{key:`reason-type-level-${i}-${_vm.reloader}`},[(
          i === 0 ||
          (i <= _vm.level &&
            reasonType.filter((v) => {
              return (
                v.parentClassificationId === _vm.reason[i - 1].classificationId
              )
            }).length)
        )?_c('CRSelect',{key:`reservation-add-complaint-select-reason-${i}-${_vm.reloader}`,attrs:{"id":`reservation-add-complaint-select-reason-${i}`,"items":i === 0
            ? reasonType
            : reasonType.filter(
                (v) =>
                  _vm.reason.length > i - 1 &&
                  v.parentClassificationId === _vm.reason[i - 1].classificationId
              ),"item-text":"label","return-object":"","label":i === 0 ? 'Complaint Type' : '',"placeholder":_vm.reasonPlaceholderText},on:{"change":(_) => _vm.onReasonSelect(i)},model:{value:(_vm.reason[i]),callback:function ($$v) {_vm.$set(_vm.reason, i, $$v)},expression:"reason[i]"}}):_vm._e()],1)}),_c('CRTextArea',{attrs:{"id":"reservation-add-complaint-form-text-area-complaint","placeholder":"Record information about the complaint here.","rows":4,"auto-grow":"","hide-details":""},model:{value:(_vm.complaintForm),callback:function ($$v) {_vm.complaintForm=$$v},expression:"complaintForm"}})],2),_c('div',{staticClass:"cr-sidebar-dialog--btn-spacer"}),_c('div',{staticClass:"cr-sidebar-dialog--action-btn",attrs:{"id":"reservation-complaint-create-complaint-button"},on:{"click":_vm.save}},[(!_vm.loading)?_c('span',[_vm._v("Create Complaint")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }