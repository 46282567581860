<template>
  <div class="reservation-add-complaint">
    <div class="reservation-add-complaint--content">
      <div v-if="operatorList.length === 0"></div>
      <div v-else-if="operatorList.length === 1">
        <b>{{ operatorList[0].companyName }}</b>
      </div>
      <div v-else>
        <CRSelect
          :id="`reservation-add-complaint-select-provider-${i}`"
          v-model="provider"
          :items="operatorList"
          item-text="companyName"
          return-object
          :label="i === 0 ? 'Provider' : ''"
          placeholder="Select Provider"
        ></CRSelect>
      </div>

      <div
        v-for="(reasonType, i) in reasons"
        :key="`reason-type-level-${i}-${reloader}`"
      >
        <CRSelect
          v-if="
            i === 0 ||
            (i <= level &&
              reasonType.filter((v) => {
                return (
                  v.parentClassificationId === reason[i - 1].classificationId
                )
              }).length)
          "
          :id="`reservation-add-complaint-select-reason-${i}`"
          :key="`reservation-add-complaint-select-reason-${i}-${reloader}`"
          v-model="reason[i]"
          :items="
            i === 0
              ? reasonType
              : reasonType.filter(
                  (v) =>
                    reason.length > i - 1 &&
                    v.parentClassificationId === reason[i - 1].classificationId
                )
          "
          item-text="label"
          return-object
          :label="i === 0 ? 'Complaint Type' : ''"
          :placeholder="reasonPlaceholderText"
          @change="(_) => onReasonSelect(i)"
        ></CRSelect>
      </div>
      <CRTextArea
        id="reservation-add-complaint-form-text-area-complaint"
        v-model="complaintForm"
        placeholder="Record information about the complaint here."
        :rows="4"
        auto-grow
        hide-details
      />
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-complaint-create-complaint-button"
      class="cr-sidebar-dialog--action-btn"
      @click="save"
    >
      <span v-if="!loading">Create Complaint</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>
<script>
import { authComputed } from '@/state/helpers'
import ticket from '@/services/ticket'

export default {
  props: {
    reservationId: {
      type: Number,
      default: 0,
    },
    reasons: {
      type: Array,
      default: () => [],
    },
    referredTo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      complaintForm: '',
      loading: false,
      reason: [],
      level: 0,
      reloader: 0,
      provider: { companyId: null, name: null },
      operatorList: [],
    }
  },
  computed: {
    ...authComputed,
    reasonPlaceholderText() {
      return 'Please select the type of complaint'
    },
  },
  mounted() {
    this.operatorList = this.referredTo.filter(
      (x) => x.referralStatus === 'accepted'
    )
    if (this.referredTo.length === 1) {
      this.provider = this.referredTo[0]
    }
  },
  methods: {
    async save() {
      this.loading = true
      let classificationId = null
      for (let c of this.reason) {
        if (c.classificationId == 0) break
        classificationId = c.classificationId
      }
      const classificationIds = []
      if (classificationId != null) {
        classificationIds.push(classificationId)
      }
      await ticket.create({
        payload: {
          assignedToId: this.currentUser.userId,
          comments: this.complaintForm,
          companyId: this.currentUser.companyId,
          createdById: this.currentUser.userId,
          reservationId: this.reservationId,
          ticketSeverityTypeId: 3,
          ticketStatusTypeId: '1',
          ticketTypeId: 2,
          title: `Complaint - ${this.reservationId}`,
          sendEmail: false,
          complaintClassificationIds: classificationIds,
          operatorId: this.provider.companyId,
        },
      })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'A ticket has been created.' },
        { root: true }
      )

      this.loading = false
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    onReasonSelect(i) {
      for (let j = i + 1; j < this.reason.length; j++)
        this.reason[j] = { classificationId: 0 }
      this.level = i + 1
      this.reloader++
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-add-complaint {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 48px;
  }
}
</style>
